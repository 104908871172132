
.service-data-scrollbar{
  height: 100%;
  ::v-deep .el-scrollbar__wrap{
    overflow-x: hidden;
    .el-scrollbar__view{
      padding: 20px;
    }
  }
}
.service-data-table{
  margin-top: 20px;
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  .data-table-header{
    display: flex;
    background: #F6F6F6;
    height: 60px;
    line-height: 60px;
    box-sizing: border-box;
    text-align: center;
    span{
      color: #666666;
      font-size: 16px;
    }
    .table-header-item{
      flex: 1;
      width: 1%;
      box-sizing: border-box;
    }
  }
  .data-table-body{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .table-body-item{
      display: flex;
      flex-direction: column;
      .body-item-top, .body-item-bottom{
        box-sizing: border-box;
        width: 100%;
        display: flex;
        border-bottom: 1px solid #EEEEEE;
        .item-top-item, .item-bottom-item{
          width: 1%;
          flex: 1;
          height: 70px;
          display: flex;
          align-items: center;
          justify-content: center;
          span{
            //width: 100%;
            display: inline-block;
          }
          i{
            line-height: 1;
            cursor: pointer;
            font-size: 18px;
            color: #7D7D7D;
            margin-right: 4px;
          }
        }
      }
    }
  }
}
